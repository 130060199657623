import React, {useMemo, useState} from 'react';
import {subDays} from 'date-fns';
import {SelectChangeEvent} from '@mui/material';
import {
	ContentBlock,
	DateRangeTemplate,
	IFilterSelectMenu,
	SelectRangeDate,
	VisualChart,
} from '@shopstat-frontend-admin-ui-kit/admin-ui-kit';

import {DateRange} from '@mui/lab/DateRangePicker/RangeTypes';
import AlertInfo from 'components/alert/AlertInfo';
import {useActiveUsersStatsDau} from 'api/users';
import {DeviceFilter} from '../../gqlApp/graphql';

const DauChartBlock: React.FC = () => {
	const [value, setValue] = useState<DateRange<Date>>([subDays(new Date(), 30), new Date()]);

	const [filter, setFilter] = useState<DeviceFilter>(DeviceFilter.All);

	const handleChange = (event: SelectChangeEvent) => {
		setFilter(event.target.value as DeviceFilter);
	};

	const filters: IFilterSelectMenu = useMemo(
		() => ({
			value: filter,
			handleChange,
			inputLabel: 'Устройство',
			filtersItems: [
				{
					value: DeviceFilter.All,
					title: 'Все',
				},
				{
					value: DeviceFilter.Browser,
					title: 'Десктоп браузер',
				},
				{
					value: DeviceFilter.Extension,
					title: 'Расширение',
				},
				{
					value: DeviceFilter.MobileApp,
					title: 'Моб. приложение',
				},
				{
					value: DeviceFilter.MobileBrowser,
					title: 'Моб. браузер',
				},
			],
		}),
		[filter],
	);

	const onChangeDate = (newValue: DateRange<Date | null>) => {
		setValue(newValue);
	};

	const {data, loading} = useActiveUsersStatsDau({
		dateRange: {
			fromDate: value[0]?.getTime(),
			toDate: value[1]?.getTime(),
		},
		device: filter,
	});

	return (
		<ContentBlock
			loading={loading}
			label={'DAU'}
			subLabel={'уникальные пользователи по дням'}
			rightHeaderBlock={
				<SelectRangeDate
					onChange={onChangeDate}
					dateRangeTemplate={DateRangeTemplate.month}
				/>
			}
		>
			<AlertInfo fromDate={value[0]?.getTime()} toDate={value[1]?.getTime()} />

			<VisualChart
				labels={data.labels}
				datasets={data.datasets}
				hideLegend
				filtersSelectMenu={filters}
			/>
		</ContentBlock>
	);
};

export default DauChartBlock;
